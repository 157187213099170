import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
var useCiqEvents = function useCiqEvents() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var watchlistTranscripts = computed(function () {
    var wid = props.wid;
    var watchlistData = $store.state.watchlist.watchlistData[wid] || {}; // the default object is moot
    // because the watchlistData[wid] appears to be prepopulated with placeholder
    return watchlistData.transcripts || {
      events: []
    };
  });
  var watchlistEvents = computed(function () {
    var idSet = new Set();
    var events = watchlistTranscripts.value.events || [];
    return events.reduce(function (acc, e) {
      if (!idSet.has(e.keydevid)) {
        acc.push(_objectSpread(_objectSpread({}, e), {}, {
          dateEpoch: new Date(e.mostimportantdateutc)
        }));
        idSet.add(e.keydevid);
      }
      return acc;
    }, []);
  });
  var now = new Date(); // split the props into
  var futureWatchlistEvents = computed(function () {
    return watchlistEvents.value.filter(function (f) {
      return f.dateEpoch > now;
    }).sort(function (a, b) {
      return a.dateEpoch - b.dateEpoch;
    });
  });
  var pastWatchlistEvents = computed(function () {
    return watchlistEvents.value.filter(function (f) {
      return f.dateEpoch <= now;
    }).sort(function (a, b) {
      return b.dateEpoch - a.dateEpoch;
    });
  });
  var fetchingCompanyTranscripts = computed(function () {
    var fetching = $store.state.ciq.fetchingTranscriptList;
    return fetching;
  });
  var companyEvents = computed(function () {
    var idSet = new Set();
    var eventData = $store.state.ciq.eventList || [];
    return eventData.reduce(function (acc, e) {
      if (!idSet.has(e.keydevid)) {
        acc.push(_objectSpread(_objectSpread({}, e), {}, {
          dateEpoch: new Date(e.mostimportantdateutc)
        }));
        idSet.add(e.keydevid);
      }
      return acc;
    }, []);
  });
  var futureCompanyEvents = computed(function () {
    return companyEvents.value.filter(function (f) {
      return f.dateEpoch > now;
    }).sort(function (a, b) {
      return a.dateEpoch - b.dateEpoch;
    });
  });
  var pastCompanyEvents = computed(function () {
    return companyEvents.value.filter(function (f) {
      return f.dateEpoch <= now;
    }).sort(function (a, b) {
      return b.dateEpoch - a.dateEpoch;
    });
  });
  return {
    watchlistTranscripts: watchlistTranscripts,
    events: watchlistEvents,
    futureWatchlistEvents: futureWatchlistEvents,
    pastWatchlistEvents: pastWatchlistEvents,
    fetchingCompanyTranscripts: fetchingCompanyTranscripts,
    companyEvents: companyEvents,
    futureCompanyEvents: futureCompanyEvents,
    pastCompanyEvents: pastCompanyEvents
  };
};
export default useCiqEvents;