import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.keys.js";
import useTranscriptsEvents from "~/functions/useWatchlistTranscriptsEvents";
import useCiqEvents from "~/functions/useCiqEvents";
import CiqEventsTable from "~/components/CiqEventsTable";
export default defineComponent({
  components: {
    CiqEventsTable: CiqEventsTable
  },
  props: {
    tickers: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    wid: {
      type: String,
      "default": ""
    },
    hourThreshold: {
      type: Number,
      "default": 0.5
    }
  },
  setup: function setup(props) {
    // this creates a reactive data property in the component
    // that is initiated by the value in the props
    var _useTranscriptsEvents = useTranscriptsEvents(props),
      error = _useTranscriptsEvents.error,
      loading = _useTranscriptsEvents.loading,
      cids = _useTranscriptsEvents.cids,
      fetchTranscripts = _useTranscriptsEvents.fetchTranscripts,
      footer = _useTranscriptsEvents.footer;
    var _useCiqEvents = useCiqEvents(props),
      watchlistTranscripts = _useCiqEvents.watchlistTranscripts,
      watchlistEvents = _useCiqEvents.watchlistEvents,
      futureWatchlistEvents = _useCiqEvents.futureWatchlistEvents,
      pastWatchlistEvents = _useCiqEvents.pastWatchlistEvents;
    var headers = [{
      text: "",
      value: "data-table-expand" // text: "Situation",
      // value: "situation",
      // align: "center",
      // class: "eventName",
    }, {
      text: "Event",
      value: "headline" // align: "center",
      // class: "eventName",
    }, {
      text: "Date",
      // value: "mostimportantdateutc",
      value: "dateEpoch" // align: "center",
      // class: "eventName",
    }];
    return {
      error: error,
      loading: loading,
      cids: cids,
      fetchTranscripts: fetchTranscripts,
      footer: footer,
      transcripts: watchlistTranscripts,
      events: watchlistEvents,
      futureWatchlistEvents: futureWatchlistEvents,
      pastWatchlistEvents: pastWatchlistEvents,
      headers: headers
    };
  },
  watch: {
    tickers: function tickers(newList) {
      if (newList.length > 0) {
        // refetch
        this.fetchTranscripts();
      }
    }
  },
  mounted: function mounted() {
    this.checkLastBeforeFetch();
  },
  methods: {
    checkLastBeforeFetch: function checkLastBeforeFetch() {
      if (Object.keys(this.cids).length > 0) {
        var lastFetch = this.transcripts.u;
        if (lastFetch) {
          var now = new Date();
          var hourDiff = (now - lastFetch) / 1000 / 60 / 60;
          if (hourDiff <= this.hourThreshold) {
            return;
          }
        }
        this.fetchTranscripts();
      }
    }
  }
});