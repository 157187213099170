import "core-js/modules/es.number.constructor.js";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
export default defineComponent({
  props: {
    dense: {
      type: Boolean,
      "default": false
    },
    inititemsperpage: {
      type: Number,
      "default": 20
    },
    events: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    columns: {
      type: Array,
      "default": function _default() {
        return [];
      } // TODO: make a reasonable default value
    },
    // FIXME: hmm footer doesn't appear to be used in the table below
    footer: {
      type: Object,
      "default": function _default() {
        return {
          itemsPerPageOptions: [10, 20, 30, 40, -1],
          options: {
            itemsPerPage: 5
          }
        };
      }
    },
    watchlist: {
      type: Boolean,
      "default": false
    },
    cidMap: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup() {
    var _useDateTimeFormatter = useDateTimeFormatters(),
      userEpochToDateTime = _useDateTimeFormatter.userEpochToDateTime,
      utcEpochToLongDate = _useDateTimeFormatter.utcEpochToLongDate;
    var singleExpand = ref(true);
    var expanded = ref([]);
    return {
      userEpochToDateTime: userEpochToDateTime,
      utcEpochToLongDate: utcEpochToLongDate,
      singleExpand: singleExpand,
      expanded: expanded
    };
  }
});